document.addEventListener('DOMContentLoaded', () => {
    const targetClass = 'magic-text-editor-content';
    const additionalClass = 'ck-content';

    const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            mutation.addedNodes.forEach(node => {
                if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains(targetClass)) {
                    node.classList.add(additionalClass);
                }
                node.querySelectorAll?.(`.${targetClass}`).forEach(childNode => {
                    childNode.classList.add(additionalClass);
                });
            });
        });
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
});
